<template>
  <div>
    <v-dialog v-model="$store.state.dialog" width="800">
      <v-card flat class="card">
        <v-toolbar outlined flat color="grey lighten-4">
          <v-toolbar-title
            >{{
              $store.state.itemDetails.dialogType == "add"
                ? $t("idhafh")
                : $t("edit")
            }}
            {{ $t("nwd-reysyh") }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form @submit.prevent="submit()" ref="form">
          <v-container>
            <v-card outlined>
              <v-container>
                <v-row>
                  <v-col cols="12" md="9">
                    <h3 class="mb-2">
                      {{ $t("ism-alnwd") }}<span class="required">*</span>
                    </h3>
                    <v-text-field
                      v-model="item.name"
                      :placeholder="$t('ism-alnwd')"
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col cols="12" md="3">
                    <h3 class="mb-2">
                      {{ $t("altslsl") }} <span class="required">*</span>
                    </h3>
                    <v-text-field
                      v-model="item.sequence"
                      type="number"
                      :placeholder="$t('altslsl')"
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col cols="12" md="12">
                    <h3 class="mb-2">
                      {{ $t("alnwdat-alfrayh") }}
                      <span class="required">*</span>
                    </h3>
                    <v-autocomplete
                      return-object
                      multiple
                      chips
                      v-debounce:500ms="getSubNodes"
                      :search-input.sync="$global.state.filter.search"
                      clearable
                      v-model="nodes"
                      item-text="name"
                      item-value="id"
                      :items="$global.state.nodes"
                      class="mr-1"
                      :placeholder="$t('alnwdat-alfrayh')"
                      :loading="$global.state.loading"
                      :no-data-text="$t('no-data')"
                      hide-details=""
                      outlined=""
                      filled=""
                    />
                  </v-col>

                  <v-col cols="12" md="12" v-if="nodes.length > 1">
                    <h3 class="mb-2">
                      {{ $t("alamlyat") }} <span class="required">*</span>
                    </h3>
                    <v-autocomplete
                      chips
                      v-model="item.operation"
                      item-text="name"
                      item-value="id"
                      :items="operations"
                      class="mr-1"
                      :placeholder="$t('alamlyat')"
                      :no-data-text="$t('no-data')"
                      hide-details=""
                      outlined=""
                      filled=""
                    />
                  </v-col>

                  <v-col cols="12" md="12" v-if="item.operation == false">
                    <h3 class="mb-2">
                      {{ $t("akhtr-alnwd-alreysyh") }}
                      <span class="required">*</span>
                    </h3>
                    <v-autocomplete
                      clearable
                      v-model="nextMainNodeId"
                      item-text="name"
                      item-value="id"
                      :items="$global.state.mainNodes"
                      class="mr-1"
                      :placeholder="$t('akhtr-alnwd-alreysyh')"
                      :loading="$global.state.loading"
                      :no-data-text="$t('no-data')"
                      hide-details=""
                      outlined=""
                      filled=""
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-col cols="12" md="12">
              <v-row class="mt-2">
                <v-btn
                  type="submit"
                  :loading="$global.state.loading"
                  x-large
                  color="primary"
                  class="btn"
                  >{{ $t("save") }}</v-btn
                >
                <v-btn
                  @click="close()"
                  x-large
                  color="error"
                  class="mr-2 btn"
                  outlined
                  >{{ $t("cancel") }}</v-btn
                >
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {},
      nodes: [],
      nextMainNodeId: "",
      operations: [
        {
          name: "OR",
          id: true,
        },
        {
          name: "AND",
          id: false,
        },
      ],
    };
  },

  mounted() {
    this.reset();

    this.$eventBus.$on("fill-fields", () => {
      console.log('this.$store.state.itemDetails');
      console.log(this.$store.state.itemDetails);
      // this.item = this.$store.state.itemDetails;
      this.item = {
        name: this.$store.state.itemDetails.name,
        id: this.$store.state.itemDetails.id,
        operation: this.$store.state.itemDetails.operation,
        sequence: this.$store.state.itemDetails.sequence,
        nextMainNodeId: this.$store.state.itemDetails.nextMainNodeId,
        workflowId: this.$store.state.itemDetails.workflowId,
        nodes: this.$store.state.itemDetails.nodes.map((node) => node.node.id),
        // newNodes: this.$store.state.itemDetails.newNodes.map(
        //   (node) => node.node.id
        // ),
      };
      let nodes = [];
      this.$store.state.itemDetails.nodes.forEach((node) => {
        this.nodes.push(node.node);
      });

      if (this.$store.state.itemDetails.dialogType != "add") {
        this.item.newNodes = this.item.nodes;
      }
    });
  },

  methods: {
    reset() {
      this.item = {
        name: "",
        operation: true,
        sequence: this.$store.state.sequence,
        // "nextMainNodeId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        workflowId: "",
        nodes: [],
        newNodes: [],
      };
      this.nextMainNodeId = "";
      this.nodes = [];
    },

    submit() {
      this.item.workflowId = this.$route.params.id;
      if (this.nextMainNodeId != "") {
        this.item.nextMainNodeId = this.nextMainNodeId;
      }
      this.item.nodes = [];
      for (let i = 0; i < this.nodes.length; i++) {
        this.item.nodes.push({
          nodeId: this.nodes[i].id,
        });
      }

      if (this.$store.state.itemDetails.dialogType == "add") {
        this.addItem();
      } else {
        this.editItem();
      }
    },

    addItem() {
      this.$global.state.loading = true;
      this.$http
        .post(`MainNode`, this.item)
        .then((res) => {
          this.$service.success(this.$t("tm-alidhafh-bnjah"));
          this.$eventBus.$emit(`refresh`);
          this.close();
        })
        .catch((err) => {
          this.$service.faild(err.response.data.message);
        })
        .finally(() => (this.$global.state.loading = false));
    },

    editItem() {
      console.log(this.item)
      this.$global.state.loading = true;
      this.item.newNodes = this.item.nodes;
      this.$http
        .put(`MainNode/${this.item.id}`, this.item)
        .then((res) => {
          this.close();
          this.$service.success(this.$t("tm-altadyl-bnjah"));
          this.$eventBus.$emit(`refresh`);
        })
        .catch((err) => {
          this.$service.faild(err.response.data.message);
        })
        .finally(() => (this.$global.state.loading = false));
    },

    close() {
      this.$store.commit("setDialog");
      this.reset();
      this.resetValidation();
      this.$store.commit("setItemDetails", {});
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    getSubNodes() {
      this.$global.dispatch(`getNodes`, this.$route.params.id);
    },
  },
  watch: {
    "$store.state.dialog"() {
      if (this.$store.state.itemDetails.dialogType == "add") {
        this.reset();
      }
    },
  },
};
</script>
